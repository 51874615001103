<template>
  <div id="index">
    <!-- 头部 -->
    <header>
      <section>
        <div class="header_img pointer" @click="goHome">
          <div class="title">
            <img src="../../src/assets/img/index/titleLogo.png" style="width:220px;height:90px;" />
          </div>
        </div>
        <div class="header_right">
          <div class="header_btnList">
            <template v-for="(item, index) in headerBtn">
              <div
                :class="[index == nowNavIndex ? 'header_activeBtn backColor' : 'header_peaceBtn', 'header_btn pointer']"
                :key="index"
                @click="chooseHeaderBtn(index)"
              >
                {{ item.value }}
              </div>
            </template>
          </div>
        </div>
        <div class="header_info">
          <div class="flex" @click="goLogin">
            <el-dropdown @command="handleCommand">
              <el-row align="middle" type="flex">
                <el-avatar :src="logo" class="iv_head" :size="50" > </el-avatar>
                <div class="userName">{{ userName }}</div>
              </el-row>
              <el-dropdown-menu slot="dropdown" v-if="loginStatus == 1">
                <el-dropdown-item command="center">个人中心</el-dropdown-item>
                <el-dropdown-item command="exit">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </section>
    </header>
    <!-- 内容 -->
    <section class="content">
      <router-view />
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CHANGE_NAVINDEX } from "../store/mutation-types";
import logo from "../assets/img/index/head.png";
export default {
  // props: {
  //   nowNavIndex: {
  //     type: String,
  //     default: "0",
  //   },
  // },
  data() {
    return {
      logo: logo,
      downMsi: "",
      loginStatus: 0,
      userName: "",
      // header部分图标
      headerImg: [
        { img: require("../assets/img/index/downLoad.png"), value: "下载聊天客户端" },
        { img: require("../assets/img/login/login_bg_r.png"), value: "用户" },
      ],
      // header部分按钮
      headerBtn: [
        { value: "首页", isShow: "1" },
        { value: "布朗课程", isShow: true },
        { value: "优秀宝宝", isShow: true },
        { value: "布朗商城", isShow: true },
        { value: "家庭学习", isShow: true },
      ],
      // 友情链接图片
      linkImgList: [
        { img: require("../assets/img/index/economics.png"), link: "http://www.ce.cn/" },
        { img: require("../assets/img/index/gov.png"), link: "https://www.gov.cn/" },
        { img: require("../assets/img/index/report.png"), link: "https://www.gjxfj.gov.cn/gjxfj/index.htm" },
        { img: require("../assets/img/index/PBOC.png"), link: "http://www.pbc.gov.cn/" },
        { img: require("../assets/img/index/CBA.png"), link: "https://www.china-cba.net/" },
        { img: require("../assets/img/index/cooperatePaper.png"), link: "http://www.zh-hz.com/" },
        { img: require("../assets/img/index/financePaper.png"), link: "https://www.financialnews.com.cn/" },
        { img: require("../assets/img/index/AnHui_CBA.png"), link: "http://www.ahbanker.com/" },
        { img: require("../assets/img/index/AnHui_Rural.png"), link: "https://www.ahrcu.com/" },
        { img: require("../assets/img/index/fy_gov.png"), link: "http://www.fy.gov.cn/" },
      ],
      // 底部图片
      qrCode: require("../assets/img/index/footerLogo.png"),

      footerObj: {
        footer_address: "",
        footer_company: "",
        footer_copyright: "",
        footer_logo: "",
        footer_public_network_security: "",
        footer_record_no: "",
      },
    };
  },
  computed: {
    ...mapState(["nowNavIndex"]),
  },
  methods: {
    initUser() {
      let user = sessionStorage.getItem("userInfo");
      console.log("user", user);
      if (user) {
        //显示登录信息
        user = JSON.parse(user);
        this.userName = user.nickName;
        this.logo = user.logo;
        this.loginStatus = 1;
      } else {
        this.userName = "请登录";
      }
    },
    // 前往首页
    goHome() {
      this.$router.push({
        path: "/home",
      });
    },

    downLoad() {
      //下载
      window.open(this.downMsi);
    },

    handleCommand(command) {
      //下拉菜单
      if (command === "center") {
        this.$router.push("/user");
      } else {
        this.$router.push("/login");
        sessionStorage.clear();
      }
    },
    goLogin() {
      //未登录跳转到登陆页
      let user = sessionStorage.getItem("userInfo");
      if (user) {
      } else {
        this.$router.push("/login");
      }
    },

    // 选择标题按钮
    chooseHeaderBtn(index) {
      this.$store.commit(CHANGE_NAVINDEX, index);
      switch (index) {
        case 0:
          this.$router.push({
            path: "/home",
          });
          break;
        case 1:
          this.$router.push({
            path: "/curriculum",
          });
          break;
        case 2:
          this.$router.push({
            path: "/partyBuilding",
          });
          break;
        case 3:
          this.$router.push({
            path: "/practice",
          });
          break;
        case 4:
          this.$router.push({
            path: "/familyLearning",
          });
          break;
        case 5:
          this.$router.push({
            path: "/introduce",
          });
          break;
        case 6:
          this.$router.push({
            path: "/opinion",
          });
          break;
      }
    },

    getFrindList() {
      //友情链接
      // this.$api.home.getFriendlyList().then((res) => {
      //   if (res.data.code === 200) {
      //     this.linkImgList = res.data.data;
      //   }
      // });
    },

    getFooter() {
      // //友情链接
      // this.$api.home.getIndexFooter().then((res) => {
      //   if (res.data.code === 200) {
      //     this.footerObj = res.data.data;
      //   }
      // });
    },

    hanelSystemConfig() {
      // //处理配置项
      // let temp = JSON.parse(sessionStorage.getItem("systemConfig"));
      // // let user = JSON.parse(sessionStorage.getItem('userInfo'))
      // this.headerImg[1].value = this.$store.state.memberName;
      // this.headerImg[1].img = this.$store.state.memberLogo;
      // this.headerBtn[1].isShow = temp.is_notice_show;
      // this.headerBtn[2].isShow = temp.is_party_build_show;
      // this.headerBtn[3].isShow = temp.is_study_show;
      // this.headerBtn[4].isShow = temp.is_special_show;
      // this.headerBtn[5].isShow = temp.is_platform_show;
      // this.headerBtn[6].isShow = temp.is_suggest_show;
      // this.logo = temp.web_logo;
      // this.downMsi = temp.chat_msi_download_url;
    },
  },
  created() {
    this.hanelSystemConfig();
    this.getFrindList();
    this.getFooter();
    //默认跳转首页
    // this.goHome();
    //显示用户信息
    this.initUser();
  },
};
</script>
<style lang="less" scoped>
#index {
  // height: 100vh;
}
.iv_head{
  /deep/ img{
    width: 100%;
  }
}
 .header_info:hover {
      cursor: pointer;
      }
header {
  width: 100%;
  min-width: 1140px;
  background: #f9fbfcff;
  box-shadow: 0 2px 16px 1px #00000026;
  section {
    display: flex;
    align-items: center;
    width: 1140px;
    height: 150px;
    margin: 0 auto;
    .title {
      
      // background: lightgrey;
    }

    .header_right {
      margin-top: 10px;
      .header_info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .header_info:hover {
      cursor: pointer;
      }
      .header_btnList {
        display: flex;
        margin-left: 20px;
        margin-top: 70px;
        align-items: center;
        .header_btn {
          padding: 4px 24px;
          margin-right: 30px;
          font-size: 18px;
          font-weight: bold;
          transition: all 0.2s linear;
        }
        .header_activeBtn {
          color: white;
          background-color: #fab300;
          border-radius: 4px;
          // background: #dd3939;
        }
        .header_peaceBtn {
          color: black;
          background: none;
        }
      }
    }
    .userName {
      //名字过长省略
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      flex: 1;
      font-size: 20px;
      margin-left: 10px;
      font-weight: bold;
      color: #999;
    }
  }
}

.content {
  margin: 0 auto;
  width: 1140px;
}
.otherLinks {
  width: 1140px;
  margin: 60px auto 40px;
  font-size: 16px;
  .otherLinks_title {
    width: width;
    height: 30px;
    padding-left: 13px;
    color: white;
    line-height: 30px;
    text-align: left;
  }
  .linkBox {
    padding: 20px 24px;
    background: #f9fbfcff;
    .linkImg {
      display: flex;

      flex-flow: wrap;
      .linkImg_item {
        width: 95px;
        height: 30px;
        margin-bottom: 10px;
      }
    }
  }
}
footer {
  width: 100%;
  margin-top: 50px;
  // background: #dd3938;
  section {
    display: flex;
    height: 200px;
    width: 1140px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    .qrcode {
      .qrImg {
        display: flex;
        width: 110px;
        height: 110px;
        align-items: center;
        justify-content: center;
        margin-bottom: 7px;
        background: white;
      }
      color: white;
    }
    .footer_info {
      padding: 30px 58px 30px 39px;
      color: white;
      text-align: left;
      border-right: 1px dashed white;
      & div:first-child {
        font-size: 24px;
        margin-bottom: 18px;
      }
    }
    .footer_record {
      margin-left: 46px;
      font-size: 14px;
      color: white;
      text-align: left;
      & > div:nth-child(n + 2) {
        margin-left: 23px;
      }

      .record_img {
        display: flex;
        align-items: center;
      }
      .pointer:hover {
        color: orange;
      }
    }
  }
}
// 背景配色
.backColor {
  background: #db3b3d;
}
</style>
